import { ChangeDetectorRef, Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { StorageService } from './services/storage.service';


interface S3File {
  eTag: string;
  key: string;
  lastModified: Date;
  size: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeComponent implements OnInit, OnDestroy {
  authState: AuthState;
  user: CognitoUserInterface;
  currentUserResultFiles: Array<S3File> = [];
  currentUserRawFiles: Array<S3File> = [];
  displayedColumns: string[] = ['key', 'download', 'lastModified', 'size'];

  constructor(private ref: ChangeDetectorRef, private storage: StorageService) { }

  ngOnInit(): void {
    console.log('init Log state change...');
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      if (authState === 'signedin') {
        this.user = authData as CognitoUserInterface;
        this.storage.indexUser().then();
        this.refreshResultFileList();
        this.refreshRawFileList();
      }
      this.ref.detectChanges();
    });
  }

  refreshResultFileList(): void {
    this.storage.listCurrentUserFiles('results/').then((files: Array<S3File>) => {
      this.currentUserResultFiles = files;
      this.ref.detectChanges();
    }).catch(err => console.error(err));
  }

  refreshRawFileList(): void {
    this.storage.listCurrentUserFiles('raw/').then((files: Array<S3File>) => {
      this.currentUserRawFiles = files;
      this.ref.detectChanges();
    }).catch(err => console.error(err));
  }

  downloadFile(filepath: string): void {
    this.storage.downloadFile(filepath).then(
      data => window.open(data)
    );
  }

  ngOnDestroy(): void { }

  titleCaseWord(word: string): string {
    if (!word) { return word; }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  filterResult(word: string): string {
    return word.replace('results/', '');
  }

  filterRaw(word: string): string {
    return word.replace('raw/', '');
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0){ return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


}
