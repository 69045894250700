import { Component, OnInit } from '@angular/core';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  fileToUpload: File = null;

  constructor(private storage: StorageService) { }

  ngOnInit(): void {   }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    const fname = `results/${files.item(0).name}`;
    this.storage.put(fname, this.fileToUpload)
      .then (result => console.log(result)) // {key: "test.txt"}
      .catch(err => console.log(err));
  }
}
