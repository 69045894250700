/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b4dde141-e95c-498a-9e0f-3cb5c8cf50cf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ldT60YEU1",
    "aws_user_pools_web_client_id": "2d40lucmo0hevugl9kv8gjdumt",
    "oauth": {},
    "aws_user_files_s3_bucket": "userdata-echolalia-org114028-echolalia",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
