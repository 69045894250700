import { Injectable } from '@angular/core';
// import { AmplifyService } from 'aws-amplify-angular';
// import { StorageProvider  } from '@aws-amplify/storage';
import { Amplify } from '@aws-amplify/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storage: any;

  constructor() {
    this.storage = Amplify.Storage;
  }

  downloadFile(key: string): Promise<any> {
    return this.storage.get(key, {level: 'private'});
  }

  put(key: string, object: any): Promise<any> {
    return this.storage.put(key, object, {level: 'private'});
  }

  async indexUser(): Promise<void> {
    const userInfo = await Amplify.Auth.currentUserInfo();
    const lock = window.localStorage.getItem(`$${userInfo.username}$`);
    console.log(lock);
    if (lock === null || lock !== 'indexed') {
      const username = userInfo.username;
      const userID = userInfo.id;
      const files = await this.storage.list(`${username}.json`, {level: 'public'});
      if (files === undefined || files.length === 0) {
        console.log('adding index file');
        await this.storage.put(`${username}.json`, {uid: userID}, {level: 'public'});
      }
      window.localStorage.setItem(`$${userInfo.username}$`, 'indexed');
    }


  }

  async listCurrentUserFiles(path: string): Promise<any> {
    return await this.storage.list(path, {level: 'private'});
  }
}
