<div *ngIf="authState == 'signedin'">
      <h2 class="page-title"> {{titleCaseWord(user.username)}} Result Files </h2>
      <br/>
      <button class="refresh-button" (click)="refreshResultFileList()"> &#x21bb;</button>
      <mat-table [dataSource]="currentUserResultFiles" class="mat-elevation-z8">

        <ng-container matColumnDef="key">
          <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{filterResult(element.key)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef> Size</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{formatBytes(element.size)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModified">
          <mat-header-cell *matHeaderCellDef> Last Modified</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.lastModified.toUTCString()}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eTag">
          <mat-header-cell *matHeaderCellDef> eTag</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.eTag}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="download">
          <mat-header-cell *matHeaderCellDef> Download</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button (click)="downloadFile(element.key)"> Download</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <br/><br/>
      <h2 class="page-title"> {{titleCaseWord(user.username)}} Raw Files </h2>
      <br/>
      <button class="refresh-button" (click)="refreshRawFileList()"> &#x21bb;</button>
      <mat-table [dataSource]="currentUserRawFiles" class="mat-elevation-z8">

        <ng-container matColumnDef="key">
          <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{filterRaw(element.key)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef> Size</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{formatBytes(element.size)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModified">
          <mat-header-cell *matHeaderCellDef> Last Modified</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.lastModified.toUTCString()}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eTag">
          <mat-header-cell *matHeaderCellDef> eTag</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.eTag}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="download">
          <mat-header-cell *matHeaderCellDef> Download</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button (click)="downloadFile(element.key)"> Download</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
</div>

<amplify-authenticator>
  <div *ngIf="authState == 'signedin'">
    <amplify-sign-out></amplify-sign-out>
  </div>
  <amplify-sign-in
    header-text="Echolalia Data Preview"
    slot="sign-in"
    [hideSignUp]="true">
  </amplify-sign-in>
  <amplify-confirm-sign-in [hidden]="true"></amplify-confirm-sign-in>
</amplify-authenticator>
